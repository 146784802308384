<template>
    <div>
        <div class="sub-title">基础信息</div>
        <el-form :inline="true" label-width="213px" :model="form" class="demo-form-inline">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="年饲养量(只)">
                        <el-input v-model="form.yearFeedingQuantity"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="年出栏量(只)">
                        <el-input v-model="form.yearAnnualSales"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="物联网设备(个)">
                        <el-input v-model="form.iotEquipmentCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="种公羊-全场养殖数据(只)">
                        <el-input v-model="form.allBreedMaleCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="种公羊-数字化养殖数据(只)">
                        <el-input v-model="form.iotBreedMaleCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="基础母羊-全场养殖数据(只)">
                        <el-input v-model="form.allBreedFemaleCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="基础母羊-数字化养殖数据(只)">
                        <el-input v-model="form.iotBreedFemaleCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="育成羊-全场养殖数据(只)">
                        <el-input v-model="form.allBredBreedCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="育成羊-数字化养殖数据(只)">
                        <el-input v-model="form.iotBredBreedCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="8">
                    <el-form-item label="哺乳羔羊-全场养殖数据(只)">
                        <el-input v-model="form.allLambBreedCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="哺乳羔羊-数字化养殖数据(只)">
                        <el-input v-model="form.iotLambBreedCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="总面积(平方米)">
                        <el-input v-model="form.sheepFarmBaseInfoTotalArea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row>
                <el-col :span="8">
                    <el-form-item label="羊舍(栋)">
                        <el-input v-model="form.sheepFarmBaseInfoShedCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="物联网设备(个)">
                        <el-input v-model="form.sheepFarmBaseInfoIotCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="防疫(件)">
                        <el-input v-model="form.taskEpidemicPreventionCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="8">
                    <el-form-item label="清粪(次)">
                        <el-input v-model="form.taskDefecationCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="消毒(次)">
                        <el-input v-model="form.taskDisinfectCount"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购(次)">
                        <el-input v-model="form.taskPurchaseCount"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="扩繁出厂均价（元/只）">
                        <el-input v-model="form.sellMultiplicationOutPrice"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="育肥出厂均价（元/只）">
                        <el-input v-model="form.sellFattenOutPrice"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <!-- <el-row class="">
                <el-form-item label="基地调度中心">
                    <el-upload
                        class="avatar-uploader"
                        action="auth-web/uploadFileOSS"
                        accept=".mp4"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <video  v-if="form.mp4" :src="form.mp4" class="avatar"></video>
                        <div v-else class="ta_c">
                            <i  style="color: #FCAC19" class="el-icon-circle-plus fs24 mt30 avatar-uploader-icon"></i>
                         
                            <div class="ta_c hint">仅支持mp4格式上传</div> 
                        </div>
                    </el-upload>
                   
                </el-form-item>
            </el-row> -->
            <!-- <el-row>
                <el-form-item label="基地名称1">
                    <el-input v-model="form.jidiVedio1Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面1">
                    <el-input style="width: 550px" v-model="form.jidiVedio1Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称2">
                    <el-input v-model="form.jidiVedio2Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面2">
                    <el-input style="width: 550px" v-model="form.jidiVedio2Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称3">
                    <el-input v-model="form.jidiVedio3Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面3">
                    <el-input style="width: 550px" v-model="form.jidiVedio3Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称4">
                    <el-input v-model="form.jidiVedio4Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面4">
                    <el-input style="width: 550px" v-model="form.jidiVedio4Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称5">
                    <el-input v-model="form.jidiVedio5Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面5">
                    <el-input style="width: 550px" v-model="form.jidiVedio5Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称6">
                    <el-input v-model="form.jidiVedio6Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面6">
                    <el-input style="width: 550px" v-model="form.jidiVedio6Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称7">
                    <el-input v-model="form.jidiVedio7Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面7">
                    <el-input style="width: 550px" v-model="form.jidiVedio7Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称8">
                    <el-input v-model="form.jidiVedio8Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面8">
                    <el-input style="width: 550px" v-model="form.jidiVedio8Url"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="基地名称9">
                    <el-input v-model="form.jidiVedio9Title"></el-input>
                </el-form-item>
                <el-form-item label="基地实时画面9">
                    <el-input style="width: 550px" v-model="form.jidiVedio9Url"></el-input>
                </el-form-item>
            </el-row> -->
        </el-form>
        <el-row class="ta_c">
            <el-button type="primary" style="min-width: 88px;" @click="onSaveInfo">保存</el-button>
        </el-row>

    </div>
</template>

<script>

export default {
    name: '',
    data(){
        return{
            form: {
            }
        }
    },
    
    mounted(){
        this.getInfo()
    },
    methods:{
        getInfo() {
            this.qa.datascreen2LoadDataBO({}).then(res => {
                if(res.respCode == '0000') {
                    this.form = res.data;
                }
            })
        },
        onSaveInfo() {
            this.qa.datascreen2DoSave(this.form).then(res => {
                if(res.respCode == '0000') {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.getInfo()
                }
            })
            
        },
        beforeAvatarUpload(file) {
            console.log(file.type)
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 10;
            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 mp4 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 10MB!');
            // }
            // return  isLt2M;
        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            this.form.mp4 = res.data.url
        },
    }
}
</script>

<style scoped>
    .sub-title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .avatar-uploader {
        width: 150px;
        height: 162px;
        border: 1px dashed #ccc;
        text-align: center;
    }
    .avatar-uploader .avatar {
        width: 150px;
        height: 162px;
    }
</style>