<template>
    <div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                羊场2大屏设置
            </div>
            <div class="setting_content_content">
                <!-- 基本信息 -->
                <BasicInformation></BasicInformation>
                <div class="line"></div>
                <!-- 羊子饲养品种 -->
                <CameraList></CameraList>
                <div class="line"></div>
                <IotMeteorologicalList></IotMeteorologicalList>
                <!-- 羊子饲养品种 -->
                <div class="line"></div>
                <VarietiesTableLsit></VarietiesTableLsit>
                <div class="line"></div>
                <!-- 人员比例 -->
                <PersonnelTableLsit></PersonnelTableLsit>
                <div class="line"></div>
                <!-- 销售记录 (单位：只) -->
                <SalesTableLsit></SalesTableLsit>
                <div class="line"></div>
                <!-- 主要饲草消耗量统计 -->
                <ConsumptionTableLsit></ConsumptionTableLsit>
                <div class="line"></div>
                <!-- 预警统计 -->
                <WarningTableLsit></WarningTableLsit>
                <div class="line"></div>
                <!-- 基础母羊 -->
                <AmountTableLsit></AmountTableLsit>
                <div class="line"></div>
                <!-- 育肥羊 -->
                <SuchTableLsit></SuchTableLsit>
                <div class="line"></div>
                <!-- 新生羊羔 -->
                <NewSheepTableLsit></NewSheepTableLsit>
            </div>
        </div>
    </div>
</template>

<script>
import BasicInformation from './components/dataScreen2_sheep/basicInformation.vue'
import CameraList from './components/dataScreen2_sheep/cameraList.vue'
import IotMeteorologicalList from './components/dataScreen2_sheep/iotMeteorologicalList.vue'
import VarietiesTableLsit from './components/dataScreen2_sheep/varietiesTableLsit.vue'
import PersonnelTableLsit from './components/dataScreen2_sheep/personnelTableLsit.vue'
import SalesTableLsit from './components/dataScreen2_sheep/salesTableLsit.vue'
import ConsumptionTableLsit from './components/dataScreen2_sheep/consumptionTableLsit.vue'
import WarningTableLsit from './components/dataScreen2_sheep/warningTableLsit.vue'
import AmountTableLsit from './components/dataScreen2_sheep/amountTableLsit.vue'
import SuchTableLsit from './components/dataScreen2_sheep/suchTableLsit.vue'
import NewSheepTableLsit from './components/dataScreen2_sheep/newSheepTableLsit.vue'

export default {
    name: '',
    data(){
        return{

        }
    },
    components: {
        BasicInformation,
        CameraList,
        VarietiesTableLsit,
        IotMeteorologicalList,
        PersonnelTableLsit,
        SalesTableLsit,
        ConsumptionTableLsit,
        WarningTableLsit,
        AmountTableLsit,
        SuchTableLsit,
        NewSheepTableLsit

    },
    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disease-box {
    position: relative;
    
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 20px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 50;
  }
  .line {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background: #ccc;
  }

  .avatar-uploader {
    width: 150px;
    height: 162px;
    border: 1px dashed #ccc;
    text-align: center;
  }
  .avatar-uploader .avatar {
    width: 150px;
    height: 162px;
  }
</style>